import { Guid } from '@/common/models/Guid';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { CommunicationSubscriptionProperties } from './CommunicationSubscriptionProperties';

/**
 * Viewmodel of CommunicationSubscription for use in the front+admin facing FromField.
 * As there is only one Form model (no front/admin split) we will match it with this one model.
 */
export class FormFieldCommunicationSubscription {
  id: Guid;
  workspaceId: Guid;

  uniqueId: string;
  name: string;
  description?: string;

  isSystemGenerated?: boolean;

  properties: CommunicationSubscriptionProperties;

  get consentFieldLabel(): string {
    return this.properties.ConsentFieldLabel;
  }

  constructor(props?: Partial<FormFieldCommunicationSubscription>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.workspaceId = Guid.valueOrNew(props.workspaceId);

    this.isSystemGenerated = asBoolean(props.isSystemGenerated);

    this.properties = new CommunicationSubscriptionProperties(props.properties);
  }
}
