import { nanoid } from 'nanoid';

import { classFromJsonOrFallback } from '../utils/TypeFunctions';

export class ButtonDataModel {
  id: string;
  text?: string;
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  url?: string;

  constructor(props?: Partial<ButtonDataModel>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.id) this.id = nanoid();
  }

  public static fromJsonOrNew(
    value: string,
    fallback?: Partial<ButtonDataModel>
  ) {
    return classFromJsonOrFallback(
      ButtonDataModel,
      value,
      new ButtonDataModel(fallback)
    );
  }
}

export interface ButtonStyle {
  backgroundColor?: string;
  color?: string;
}
