import { Guid } from '@/common/models/Guid';

import { OptionModel } from '../../OptionModel';
import { FormFieldContactPropertyOptionProperties } from './FormFieldContactPropertyOptionProperties';

/**
 * Viewmodel of ContactPropertyOption for use in the front+admin facing FromField.
 * As there is only one Form model (no front/admin split) we will match it with this one option model.
 */
export class FormFieldContactPropertyOption {
  id: Guid;
  propertyDefinitionId: Guid;
  order: number;
  label: string;
  value: string;

  properties: FormFieldContactPropertyOptionProperties;

  get Image() {
    return this.properties.image;
  }

  toOptionModel(): OptionModel {
    return {
      label: this.label,
      value: this.id.toString()
    };
  }

  constructor(props?: Partial<FormFieldContactPropertyOption>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.propertyDefinitionId = Guid.valueOrEmpty(props.propertyDefinitionId);
    this.properties = new FormFieldContactPropertyOptionProperties(
      props.properties
    );
  }
}
