import { FormFileAllowedType, getFileType } from '@/common/utils/FileFunctions';

export enum AssetTypes {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
  Pdf = 'Pdf'
}

export const getAssetTypeFromFile = (file: File) => {
  const type = getFileType(file);
  return toAssetType(type);
};

export const toAssetType = (type: FormFileAllowedType) => {
  switch (type) {
    case 'image':
      return AssetTypes.Image;
    case 'pdf':
      return AssetTypes.Pdf;
    case 'video':
      return AssetTypes.Video;
    default:
      return AssetTypes.Unknown;
  }
};
