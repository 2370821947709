import isArray from 'lodash/isArray';

import { ImageDataModel } from '@/common/models/ImageDataModel';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { classFromJson } from '@/common/utils/TypeFunctions';

export class FormProperties {
  Header?: string;
  SubHeader?: string;
  TermsLink?: string;
  TermsText?: string;
  ButtonUrl?: string;
  static ButtonTextDefault: string = 'Submit';
  ButtonText: string = FormProperties.ButtonTextDefault;

  BackgroundColor: string = '#FFFFFF';
  TextColor: string = '#000000';

  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  HeaderImageUrl?: string;

  get HeaderImage() {
    return ImageDataModel.fromJsonOrUrl(this.HeaderImageUrl);
  }

  EntryButtonText?: string;
  static SubmittedMessageDefault: string =
    '<p><br /><p><strong>GOOD LUCK - YOU&#x27;RE IN!</strong><br /><br />Don&#x27;t forget to check back once time&#x27;s up.</p><br /></p>';
  SubmittedMessage?: string = FormProperties.SubmittedMessageDefault;
  SubmittedMessageEditorStateJson?: string;
  SubmittedMessageEnabled?: boolean;

  static ClosedMessageDefault: string =
    '<p><br /><p><strong>HAVE YOU WON?</strong><br /><br />The winner will be announced shortly.</p><br /></p>';
  ClosedMessage?: string = FormProperties.ClosedMessageDefault;
  ClosedMessageEditorStateJson?: string;
  ClosedMessageEnabled?: boolean;
  AnswerRevealedMessage?: string;
  WebhookUrl?: string;
  WebhookUrlHttpHeaders?: string;
  WebhookUrlBodyFields?: string;

  ReferenceTableFieldMappingsJson?: string;
  ReferenceTablePostEntryUpdatesJson?: string;

  constructor(props?: Partial<FormProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.SubmittedMessageEnabled = asBoolean(
      props.SubmittedMessageEnabled,
      true
    );
    this.ClosedMessageEnabled = asBoolean(props.ClosedMessageEnabled, true);

    if (!this.ButtonText) {
      this.ButtonText = FormProperties.ButtonTextDefault;
    }
  }

  get referenceTableFieldMappings(): ReferenceTableFieldMappingModel[] {
    return ReferenceTableFieldMappingModel.listFromJson(
      this.ReferenceTableFieldMappingsJson
    );
  }

  setReferenceTableMappingsImmutable(
    mappings: ReferenceTableFieldMappingModel[]
  ) {
    return new FormProperties({
      ...this,
      ReferenceTableFieldMappingsJson: JSON.stringify(mappings),
      ReferenceTablePostEntryUpdatesJson: undefined
    });
  }

  get referenceTablePostEntryUpdates(): ReferenceTablePostEntryUpdates {
    return classFromJson(
      ReferenceTablePostEntryUpdates,
      this.ReferenceTablePostEntryUpdatesJson
    );
  }

  setReferenceTablePostEntryUpdates(details: ReferenceTablePostEntryUpdates) {
    return new FormProperties({
      ...this,
      ReferenceTableFieldMappingsJson: undefined,
      ReferenceTablePostEntryUpdatesJson: JSON.stringify(details)
    });
  }
}

export class ReferenceTableFieldMappingModel {
  Id: string;
  SchemaColumnName: string;
  FormFieldName: string;
  ValidationErrorMessage?: string;

  constructor(props?: Partial<ReferenceTableFieldMappingModel>) {
    props = props || {};
    Object.assign(this, props);
  }

  public static getValidationErrorMessageOrDefault(
    model: ReferenceTableFieldMappingModel
  ) {
    return (
      model.ValidationErrorMessage ||
      ReferenceTableFieldMappingModel.defaultValidationErrorMessage
    );
  }

  public static defaultValidationErrorMessage =
    'Please confirm your details are correct for this field';

  static listFromJson(json: string) {
    const fallback: ReferenceTableFieldMappingModel[] = [];
    try {
      const rawItems = JSON.parse(json || '[]');
      if (!isArray(rawItems)) {
        return fallback;
      }
      return mapArray(rawItems, (x) => new ReferenceTableFieldMappingModel(x));
    } catch (error) {
      console.error(error);
      return fallback;
    }
  }
}

export class ReferenceTablePostEntryUpdates {
  Matches: ReferenceTableFieldMappingModel[];
  Updates: ReferenceTableFieldMappingModel[];

  constructor(props?: Partial<ReferenceTablePostEntryUpdates>) {
    props = props || {};
    Object.assign(this, props);
    this.Matches = mapArray(
      props.Matches,
      (x) => new ReferenceTableFieldMappingModel(x)
    );
    this.Updates = mapArray(
      props.Updates,
      (x) => new ReferenceTableFieldMappingModel(x)
    );
  }
}
