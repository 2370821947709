import { FCC } from 'fcc';

import { OptionalToasterProvider } from '@/common/components/Toast';
import { ThemeProvider, ThemeProviderProps } from '@/theme';

import { OptionalPageLayoutContent } from './PageLayoutContent';
import { PageLayoutMeta } from './PageLayoutMeta';

export interface PageLayoutProps
  extends Pick<ThemeProviderProps, 'theme' | 'forceColorScheme'> {
  title?: string;
  withLayoutContent?: boolean;
  withToastProvider?: boolean;
}

export const PageLayout: FCC<PageLayoutProps> = ({
  withLayoutContent = true,
  withToastProvider = true,
  title,
  theme,
  forceColorScheme,
  children
}) => {
  return (
    <>
      <PageLayoutMeta title={title} />
      <ThemeProvider theme={theme} forceColorScheme={forceColorScheme}>
        <OptionalToasterProvider hide={!withToastProvider}>
          <OptionalPageLayoutContent hide={!withLayoutContent}>
            {children}
          </OptionalPageLayoutContent>
        </OptionalToasterProvider>
      </ThemeProvider>
    </>
  );
};
