import { parseThemeColor } from '@mantine/core';

import { KeyOf } from '@/common/models/KeyOf';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { Theme, theme } from '@/theme';

export interface GetPrimaryColorProps {
  overrideColor?: string;
  card?: SiteCard;
  properties?: SitePageProperties;
  fallback?: KeyOf<Theme['colors']> | string;
  theme?: Theme;
}

export const getPrimaryBackgroundColor = ({
  overrideColor,
  card,
  properties,
  fallback = 'komo-blue.8',
  theme: themeProp
}: GetPrimaryColorProps): string => {
  return (
    overrideColor ||
    card?.properties?.ButtonBackgroundColor ||
    properties?.AccentBackgroundColor ||
    parseThemeColor({ color: fallback, theme: themeProp || theme }).value
  );
};

export const getPrimaryTextColor = ({
  overrideColor,
  card,
  properties,
  fallback = '#ffffff',
  theme: themeProp
}: GetPrimaryColorProps): string => {
  return (
    overrideColor ||
    card?.properties?.ButtonTextColor ||
    properties?.AccentTextColor ||
    parseThemeColor({ color: fallback, theme: themeProp || theme }).value
  );
};
