import { Notification as MantineNotification } from '@mantine/core';
import { FC } from 'react';

import { CheckIcon } from '@/common/components/Icons/CheckIcon';
import { ErrorIcon } from '@/common/components/Icons/ErrorIcon';
import { InfoIcon } from '@/common/components/Icons/InfoIcon';
import { RefreshIcon } from '@/common/components/Icons/RefreshIcon';
import { WarningIcon } from '@/common/components/Icons/WarningIcon';
import { Image } from '@/common/components/Image/Image';
import { Spinner } from '@/common/components/Spinner';

import classes from './ToastItem.module.css';
import { ToastMessage } from './ToastMessage';
import { ToastTypes } from './ToastTypes';

const getToastIcon = (type: ToastTypes) => {
  const iconSize = 'md';
  switch (type) {
    case ToastTypes.Info:
      return <InfoIcon size={iconSize} />;
    case ToastTypes.Success:
      return <CheckIcon size={iconSize} />;
    case ToastTypes.Error:
      return <ErrorIcon size={iconSize} />;
    case ToastTypes.Warning:
      return <WarningIcon size={iconSize} />;
    case ToastTypes.Default:
    default:
      return null;
  }
};

const getToastColor = (type: ToastTypes) => {
  switch (type) {
    case ToastTypes.Info:
      return 'blue';
    case ToastTypes.Success:
      return 'green';
    case ToastTypes.Error:
      return 'red';
    case ToastTypes.Warning:
      return 'orange';
    case ToastTypes.Default:
      return 'blue';
    default:
      return null;
  }
};

interface Props {
  toast: ToastMessage;
  onRemove: (id: string) => void;
}

export const ToastItem: FC<Props> = ({ toast, onRemove }) => {
  const handleClose = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    toast.config.onClosed?.();
    onRemove(toast.id);
  };
  const handleClick = () => {
    toast.config.onClick?.();
    handleClose();
  };
  return (
    <MantineNotification
      radius={toast.config.toastRadius}
      classNames={{ ...classes }}
      style={toast.type === ToastTypes.Pill ? { margin: '0 auto' } : undefined}
      styles={
        !!toast.image ? (_) => ({ icon: { background: 'none' } }) : undefined
      }
      onClick={handleClick}
      onClose={((e: React.MouseEvent) => handleClose(e)) as any}
      withCloseButton={toast.config.actionIcon == 'close'}
      title={toast.title}
      data-pill={toast.type === ToastTypes.Pill}
      data-icon-spinner={toast.loading ? 'true' : 'false'}
      icon={
        toast.loading ? (
          <Spinner />
        ) : toast.image ? (
          <Image objectFit="cover" image={toast.image} />
        ) : !!toast.icon ? (
          toast.icon
        ) : (
          getToastIcon(toast.type)
        )
      }
      color={getToastColor(toast.type)}
    >
      <span>
        {toast.message}
        {toast.config.actionIcon == 'refresh' && (
          <RefreshIcon ml={'0.5rem'} size={'1.25rem'} />
        )}
      </span>
    </MantineNotification>
  );
};
