import Color from 'color';
import isNil from 'lodash/isNil';

import { ColorRGBA } from '../models/ColorRGBA';

export const hexToRGB = (hex: string, alpha?: number | string) => {
  const alphaNum = typeof alpha === 'string' ? parseFloat(alpha) : alpha;
  const rgbNums = hexToRGBNumbers(hex, alphaNum);
  if (!rgbNums) {
    return undefined;
  }

  if (!isNil(rgbNums.a)) {
    return `rgba(${rgbNums.r}, ${rgbNums.g}, ${rgbNums.b}, ${rgbNums.a})`;
  } else {
    return `rgb(${rgbNums.r}, ${rgbNums.g}, ${rgbNums.b})`;
  }
};

export const hexToRGBNumbers = (hex: string, alpha?: number) => {
  if (!hex) {
    return undefined;
  }

  if (hex.startsWith('rgb')) {
    const color = new ColorRGBA(hex);

    return {
      r: color.r,
      g: color.g,
      b: color.b,
      a: !isNil(color.a) ? color.a : alpha
    };
  }

  if (hex.length !== 7 && hex.length !== 9 && hex.length !== 4) {
    return undefined;
  }
  if (hex.length == 9) {
    alpha = parseFloat((parseInt(hex.slice(7, 9), 16) / 255).toFixed(2));
  }
  if (hex.length == 4) {
    hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b, a: alpha };
};

export const colorWithAlpha = (
  value: Partial<ColorRGBA> | string,
  alpha: number
) => {
  return new ColorBuilder(value).withAlpha(alpha).build();
};

//TODO GET BETTER NAME
export const getSlightDifferenceColor = (
  color: ColorRGBA | string,
  ratio: number = 0.5
) => {
  const colorRgba = new ColorRGBA(color);
  const model = Color(colorRgba.toString()).alpha(0.5);

  return colorRgba.getContrastColor({
    darkColor: model.lighten(ratio).hexa(),
    lightColor: model.darken(ratio).hexa()
  });
};

export class ColorBuilder {
  private _color: ColorRGBA;
  constructor(value: Partial<ColorRGBA> | string) {
    this._color = new ColorRGBA(value);
  }

  withAlpha(value: number) {
    this._color = new ColorRGBA({
      r: this._color.r,
      g: this._color.g,
      b: this._color.b,
      a: value
    });
    return this;
  }

  build() {
    return this._color;
  }
}

export interface IRgbColor {
  r: number;
  g: number;
  b: number;
}

export const rgbaToHex = ({ r, g, b }: IRgbColor): string => {
  const bin = (r << 16) | (g << 8) | b;
  return `#${((h) => new Array(7 - h.length).join('0') + h)(bin.toString(16))}`;
};

export interface RgbaColor extends IRgbColor {
  a: number;
}

export const rgbaToHexa = ({ r, g, b, a }: RgbaColor): string => {
  const alpha =
    typeof a === 'number' && ((a * 255) | (1 << 8)).toString(16).slice(1);
  return `${rgbaToHex({ r, g, b })}${alpha ? alpha : ''}`;
};
