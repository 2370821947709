import { immerable } from 'immer';
import orderBy from 'lodash/orderBy';

import { FieldTypes } from '@/common/models/form/FormFieldTypes';
import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';
import { classFromJsonOrNew } from '@/common/utils/TypeFunctions';
import { decodeURIComponentWithSanitizing } from '@/common/utils/UrlFunctions';

export class TextFieldConfig {
  [immerable] = true;

  Placeholder?: string;

  ValidationRegex?: string;
  RegexErrorMessage?: string;

  InputMode?: string;

  constructor(props?: Partial<TextFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(TextFieldConfig, json);
  }
}

export class TextAreaFieldConfig {
  [immerable] = true;

  Placeholder?: string;
  WordLimit: number;

  constructor(props?: Partial<TextAreaFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
    this.WordLimit = asNumber(props.WordLimit, 0);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(TextAreaFieldConfig, json);
  }
}

export class NumberFieldConfig {
  [immerable] = true;

  Max?: number;
  Min?: number;
  HideControls?: boolean;

  ValidationRegex?: string;
  RegexErrorMessage?: string;

  constructor(props?: Partial<NumberFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(NumberFieldConfig, json);
  }
}

export class OptinFieldConfig {
  [immerable] = true;

  DefaultValue?: boolean;
  Text?: string;
  TextEditorStateJson?: string;

  constructor(props?: Partial<OptinFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(OptinFieldConfig, json);
  }
}

export class PhoneFieldConfig {
  [immerable] = true;

  /**
   * Optional list of ISO2 country codes to restrict the phone field to.
   */
  AllowedCountries?: string[];

  constructor(props?: Partial<PhoneFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(PhoneFieldConfig, json);
  }
}

export type DropdownDefault = 'Placeholder' | 'Option';

export class DropdownFieldConfig {
  [immerable] = true;

  DefaultsTo?: DropdownDefault;
  Placeholder?: string;
  DefaultOption?: Guid;

  constructor(props?: Partial<DropdownFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
    this.DefaultOption = !!props.DefaultOption
      ? Guid.valueOrNew(props.DefaultOption)
      : undefined;
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(DropdownFieldConfig, json);
  }
}

export class OptionFieldConfig {
  [immerable] = true;

  HideLabel?: boolean;

  constructor(props?: Partial<OptionFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(OptionFieldConfig, json);
  }
}

export class AddressFieldConfig {
  [immerable] = true;

  AllowedCountries: string[];
  Placeholder?: string;

  constructor(props?: Partial<AddressFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(AddressFieldConfig, json);
  }
}

export class CountryFieldConfig {
  [immerable] = true;

  /**
   * Optional list of ISO2 country codes to restrict the country field to.
   */
  AllowedCountries?: string[];

  constructor(props?: Partial<CountryFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(CountryFieldConfig, json);
  }
}

export class BirthdayFieldConfig {
  [immerable] = true;

  MinimumAge?: number;

  constructor(props?: Partial<BirthdayFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(BirthdayFieldConfig, json);
  }
}

export class BirthYearFieldConfig {
  [immerable] = true;

  Placeholder?: string;

  constructor(props?: Partial<BirthYearFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(BirthYearFieldConfig, json);
  }
}

export enum UniqueCodeSourceVariants {
  Field = 'Field',
  ReferenceTable = 'ReferenceTable'
}

export class UniqueCodeFieldConfig {
  [immerable] = true;

  Codes?: string[];
  CodesVariant: UniqueCodeSourceVariants;
  ReferenceTableColumn?: string;
  LimitEachCodePerUser?: number;
  LimitEachCode?: number;

  get isReferenceTable() {
    return this.CodesVariant === UniqueCodeSourceVariants.ReferenceTable;
  }

  get hasCodes() {
    return !!this.Codes?.length;
  }

  get hasTooManyCodes() {
    if (this.hasCodes) {
      return false;
    }

    return this.Codes.length > 10000;
  }

  get hasReferenceTableColumn() {
    return !!this.ReferenceTableColumn;
  }

  constructor(props?: Partial<UniqueCodeFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
    this.Codes = mapArray(this.Codes, (x) => x);
    if (!this.CodesVariant) {
      this.CodesVariant = UniqueCodeSourceVariants.Field;
    }
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(UniqueCodeFieldConfig, json);
  }
}

export class QrCodeFieldConfig {
  [immerable] = true;

  QrUrl?: string;
  Codes?: string[];

  get hasCodes() {
    return !!this.Codes?.length;
  }

  constructor(props?: Partial<QrCodeFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
    this.Codes = mapArray(this.Codes, (x) => x);
  }

  containsValidCode(value: string) {
    const loweredValue = (value || '').trim().toLowerCase();
    if (!loweredValue) {
      return [false, null] as const;
    }

    const foundCodes: string[] = [];
    for (const c of this.Codes || []) {
      const codeCompare = c.trim().toLowerCase();
      if (
        loweredValue.includes(codeCompare) ||
        decodeURIComponentWithSanitizing(loweredValue).includes(codeCompare)
      ) {
        foundCodes.push(c);
      }
    }

    if (foundCodes.length === 0) {
      return [false, null] as const;
    }

    const code = orderBy(foundCodes, 'length', 'desc')[0];
    return [true, code] as const;
  }
  static fromJson(json: string) {
    return classFromJsonOrNew(QrCodeFieldConfig, json);
  }
}

export class HtmlFieldConfig {
  [immerable] = true;

  Html?: string;

  constructor(props?: Partial<HtmlFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrNew(HtmlFieldConfig, json);
  }
}

export interface EmptyFieldConfig {}

export type FieldConfig =
  | AddressFieldConfig
  | BirthYearFieldConfig
  | BirthdayFieldConfig
  | CountryFieldConfig
  | DropdownFieldConfig
  | NumberFieldConfig
  | OptinFieldConfig
  | OptionFieldConfig
  | PhoneFieldConfig
  | TextAreaFieldConfig
  | TextFieldConfig
  | UniqueCodeFieldConfig
  | QrCodeFieldConfig
  | HtmlFieldConfig
  | EmptyFieldConfig;

export interface IHasPlaceholder {
  Placeholder: string;
}

export function isIHasPlaceholder(object: any): object is IHasPlaceholder {
  return (
    !!object && !!object.Placeholder && typeof object.Placeholder === 'string'
  );
}

export interface IHasWordLimit {
  WordLimit: number;
}

export function isIHasWordLimit(object: any): object is IHasWordLimit {
  return !!object && !!object.WordLimit && typeof object.WordLimit === 'number';
}

export const initTypedFieldConfig = (
  fieldType: FieldTypes,
  configJson: string = '{}'
): FieldConfig => {
  switch (fieldType) {
    case FieldTypes.Text:
    case FieldTypes.Email:
      return TextFieldConfig.fromJson(configJson);
    case FieldTypes.TextArea:
      return TextAreaFieldConfig.fromJson(configJson);
    case FieldTypes.Phone:
      return PhoneFieldConfig.fromJson(configJson);
    case FieldTypes.Number:
    case FieldTypes.Currency:
      return NumberFieldConfig.fromJson(configJson);
    case FieldTypes.Dropdown:
      return DropdownFieldConfig.fromJson(configJson);
    case FieldTypes.Birthday:
      return BirthdayFieldConfig.fromJson(configJson);
    case FieldTypes.Optin:
      return OptinFieldConfig.fromJson(configJson);
    case FieldTypes.Option:
      return OptionFieldConfig.fromJson(configJson);
    case FieldTypes.Country:
      return CountryFieldConfig.fromJson(configJson);
    case FieldTypes.UniqueCode:
      return UniqueCodeFieldConfig.fromJson(configJson);
    case FieldTypes.BirthYear:
      return BirthYearFieldConfig.fromJson(configJson);
    case FieldTypes.Address:
      return AddressFieldConfig.fromJson(configJson);
    case FieldTypes.Html:
      return HtmlFieldConfig.fromJson(configJson);
    case FieldTypes.QrCode:
      return QrCodeFieldConfig.fromJson(configJson);
    case FieldTypes.Percentage:
    case FieldTypes.MultipleSelect:
    case FieldTypes.Date:
    case FieldTypes.DateTime:
    case FieldTypes.Password:
    case FieldTypes.Code:
    case FieldTypes.File:
    case FieldTypes.Hidden:
      return {};

    default:
      const unsupported: never = fieldType;
      throw new Error(`Unsupported variant: ${unsupported}`);
  }
};
