import isNil from 'lodash/isNil';

import {
  hexToRGBNumbers,
  rgbaToHex,
  rgbaToHexa
} from '@/common/utils/ColorFunctions';
import { roundTo2Decimals } from '@/common/utils/NumberFunctions';

export class ColorRGBA {
  public static readonly WhiteRgb = new ColorRGBA({ r: 255, g: 255, b: 255 });
  public static readonly WhiteRgba = new ColorRGBA({
    r: 255,
    g: 255,
    b: 255,
    a: 1
  });

  /**
   * Red value. A value between 0 - 255;
   */
  readonly r: number = 255;

  /**
   * Green value. A value between 0 - 255;
   */
  readonly g: number = 255;

  /**
   * Blue value. A value between 0 - 255;
   */
  readonly b: number = 255;

  /**
   * Alpha. A fractional number between 0 and 1. Optional.
   */
  readonly a?: number;

  constructor(props?: Partial<ColorRGBA> | string) {
    if (!props) {
      Object.assign(this, ColorRGBA.WhiteRgb);
      return;
    }

    if (props instanceof ColorRGBA) {
      Object.assign(this, { ...props });
      return;
    }

    if (typeof props === 'string') {
      if (props === 'black') props = '#000000';
      else if (props === 'white') props = '#FFFFFF';
      else if (props === 'transparent') props = '#00000000';
    }

    if (
      typeof props === 'string' &&
      props[0] !== '#' &&
      (props.length == 6 || props.length == 8)
    ) {
      props = `#${props}`;
    }
    if (typeof props === 'string' && props[0] === '#') {
      Object.assign(this, ColorRGBA.fromHex(props));
      return;
    }

    if (typeof props === 'string') {
      try {
        const pattern = /\((.*?)\)/;
        const match = props.match(pattern);
        if (match?.length) {
          const matchValues = match[1].split(',');
          this.r = parseInt(matchValues[0]);
          this.g = parseInt(matchValues[1]);
          this.b = parseInt(matchValues[2]);
          if (matchValues.length > 3) {
            this.a = roundTo2Decimals(parseFloat(matchValues[3]));
          }
          return;
        }
        const partial = JSON.parse(props) as Partial<ColorRGBA>;

        if (
          partial.r === undefined ||
          partial.g === undefined ||
          partial.b === undefined
        ) {
          Object.assign(this, ColorRGBA.WhiteRgb);
          return;
        }

        Object.assign(this, partial);
        return;
      } catch (e) {
        console.error(e);
        Object.assign(this, ColorRGBA.WhiteRgb);
        return;
      }
    }

    if (
      typeof props !== 'string' &&
      (props.r === undefined || props.g === undefined || props.b === undefined)
    ) {
      Object.assign(this, ColorRGBA.WhiteRgb);
      return;
    }

    Object.assign(this, props);
  }

  get hasAlpha(): boolean {
    return !isNil(this.a);
  }

  get hex(): string {
    return rgbaToHex({
      r: this.r,
      g: this.g,
      b: this.b
    });
  }

  get hexa(): string {
    return rgbaToHexa({
      r: this.r,
      g: this.g,
      b: this.b,
      a: roundTo2Decimals(this.a)
    });
  }

  get rgb(): string {
    return `rgb(${this.r},${this.g},${this.b})`;
  }

  get rgba(): string {
    return `rgba(${this.r},${this.g},${this.b},${roundTo2Decimals(this.a)})`;
  }

  public toString(): string {
    return this.hasAlpha ? this.rgba : this.rgb;
  }

  public toJSON(): string {
    return this.hasAlpha ? this.hexa : this.hex;
  }

  //https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
  getContrastColor(options?: GetContrastColorOptions) {
    const isDark = this.isDark();
    return isDark
      ? options?.lightColor || '#FFFFFF'
      : options?.darkColor || '#000000';
  }

  isDark() {
    const yiq = (this.r * 299 + this.g * 587 + this.b * 114) / 1000;
    return yiq < 128;
  }

  static fromRGBA(r: number, g: number, b: number, a?: number): ColorRGBA {
    return new ColorRGBA({ r, g, b, a });
  }

  static fromHex(hex: string): ColorRGBA {
    return new ColorRGBA(hexToRGBNumbers(hex));
  }

  static isDark(color: string) {
    return new ColorRGBA(color);
  }

  static fromColorAndAlpha(color: string, alpha: number) {
    const value = new ColorRGBA(color);
    return new ColorRGBA({ r: value.r, g: value.g, b: value.b, a: alpha });
  }
}

export const colorRGBAOrUndefined = (value: ColorRGBA | string | undefined) =>
  value ? new ColorRGBA(value) : undefined;

export const colorRGBAOrDefault = (
  value: ColorRGBA | string | undefined,
  defaultValue: ColorRGBA
) => (value ? new ColorRGBA(value) : defaultValue);

interface GetContrastColorOptions {
  darkColor?: string;
  lightColor?: string;
}
