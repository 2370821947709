import { ElementProps, MantineSize } from '@mantine/core';
import cx from 'clsx';
import { ElementType } from 'react';

import { BoxProps } from '@/common/components/Display/Box';

export interface IHasIconSize {
  size?: MantineSize | string | number;
}

export interface IHasIconColors {
  color?: string;
  color2?: string;
}

export interface IHasIconSizeAndColors extends IHasIconSize, IHasIconColors {}

export interface IconProps
  extends IHasIconSize,
    IHasIconColors,
    Omit<BoxProps, 'h' | 'w'>,
    ElementProps<'span', 'onClick' | 'size'> {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  component?: ElementType;
  href?: string;
  flipped?: boolean;
  target?: string;
  mixBlendDifference?: boolean;
  svgProps?: Omit<
    React.SVGProps<SVGSVGElement>,
    'size' | 'height' | 'width' | 'viewBox'
  >;
}

export interface ColorIconProps extends Omit<IconProps, 'color' | 'color2'> {}

export const tablerIconClasses = (className?: string) =>
  cx(className, 'icon-tabler');
