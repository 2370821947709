/**
 * Returns a clean version of the field name.
 * @param value The value to clean.
 * @return The clean version of the name.
 */
export const cleanFieldName = (value: string) =>
  (value || '')
    .replace(/([\s])/gi, '_')
    .replace(/([^a-zA-Z0-9_]+)/gi, '_')
    .replace(/([_]+)/gi, '_')
    .replace(/^([_]+)(.*)$/gi, '$2')
    .toLowerCase();
